import { Field, FieldRenderProps, useField } from "react-final-form";

import {
  ChoiceGroupOption,
  IChoiceGroupOption,
  IChoiceGroupOptionProps
} from "@bps/fluent-ui";

import { ExposedFieldProps } from "./FinalForm.tsx";

type HtmlElementType = HTMLElement | HTMLInputElement;

export type ChoiceGroupOptionAdapterProps = Omit<
  IChoiceGroupOptionProps,
  | "value"
  | "defaultValue"
  | "defaultChecked"
  | "defaultSelectedKey"
  | "required"
  | "checked"
  | "onChange"
  | "text"
> & { label?: string };

export type ChoiceGroupOptionFieldProps = ExposedFieldProps<
  IChoiceGroupOptionProps["itemKey"],
  HtmlElementType
> &
  ChoiceGroupOptionAdapterProps;

const ChoiceGroupOptionAdapter = (
  props: FieldRenderProps<IChoiceGroupOptionProps["itemKey"], HtmlElementType> &
    ChoiceGroupOptionAdapterProps
) => {
  const { input, readOnly, itemKey, disabled, label, styles } = props;

  const { meta: formMeta } = useField(input.name);

  const onChange = (
    ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
    option?: IChoiceGroupOption
  ) => {
    input.onChange(option?.key);
  };

  const id = input.name + itemKey;

  return (
    <ChoiceGroupOption
      text={label ?? ""}
      itemKey={itemKey}
      disabled={
        readOnly ||
        formMeta?.data?.readOnly ||
        disabled ||
        formMeta?.data?.disabled
      }
      onChange={onChange}
      checked={itemKey === input.value}
      onBlur={input.onBlur}
      onFocus={input.onFocus}
      id={id}
      name={input.name}
      onRenderLabel={(prop, defaultRender) => {
        if (!!label && defaultRender) {
          return defaultRender(prop);
        } else {
          return null;
        }
      }}
      styles={styles}
    />
  );
};

/**
 * Use ChoiceGroupOptionField with final-form.
 * @param props
 */
export const ChoiceGroupOptionField: React.FC<
  ChoiceGroupOptionFieldProps
> = props => <Field {...props} component={ChoiceGroupOptionAdapter} />;
