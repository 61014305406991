import {
  DateTimeFramePickerStyles,
  DateTimeFramePickerStylesProps
} from "./DateTimeFramePicker.types.ts";

export const getDateTimeFrameStyles = ({
  theme
}: DateTimeFramePickerStylesProps): DateTimeFramePickerStyles => {
  return {
    field: {
      color: theme.palette.neutralSecondary,
      transition: "background-color 0.5s ease",
      "&:hover": {
        backgroundColor: theme.palette.neutralLighter,
        borderRadius: 2
      },
      padding: 8,
      alignItems: "center",
      textAlign: "center"
    }
  };
};
