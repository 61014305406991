import { dataAttribute, DataAttributes, Stack, Text } from "@bps/fluent-ui";
import { DATE_FORMATS, isDefined } from "@bps/utils";
import {
  AddressDto,
  ContactType
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactsFilter } from "@shared-types/practice/contacts-filter.interface.ts";

import { ContactPicker } from "./ContactPicker.tsx";
import { ContactPickerProps } from "./ContactPicker.types.ts";

export const onResolveSuggestionItem: ContactPickerProps["onResolveSuggestionItem"] =
  (contact, context) => {
    const address: AddressDto | undefined =
      contact.physicalAddress ?? contact.postalAddress;

    const getNumberOrAddress = () => {
      if (context.searchBy === "phoneNumber") {
        if (context && isDefined(context.searchValue)) {
          const phone = contact.phoneCommunicationsOnly.find(x =>
            x.value.includes(context.searchValue!)
          );
          if (phone) {
            return phone.value;
          }
        }
        return contact.phone;
      } else {
        return address && address.suburb;
      }
    };

    return {
      showSecondaryText: context.role === "suggestion",
      styles:
        context.role === "suggestion"
          ? () => ({ root: { minHeight: 36 } })
          : undefined,
      onRenderSecondaryText: () => (
        <Stack horizontal tokens={{ childrenGap: 16 }}>
          {contact.isPatient && (
            <Text
              variant="small"
              {...dataAttribute(
                DataAttributes.Element,
                "persona-option-birthday"
              )}
            >
              {contact.birthDate
                ? contact.birthDate.toFormat(DATE_FORMATS.DAY_DEFAULT_FORMAT)
                : "No DOB rec."}
            </Text>
          )}
          <Text
            variant="small"
            {...dataAttribute(DataAttributes.Element, "persona-option-address")}
          >
            {getNumberOrAddress()}
          </Text>
        </Stack>
      )
    };
  };

/**
 * Specialized ContactPicker that only allows the selection of a patient contact.
 * Note that it uses the contact API from practice and it won't result in the presence of
 * a patient model in the store.
 */
export const PatientPicker = (props: ContactPickerProps) => {
  const filter: ContactsFilter = {
    ...props.filter,
    types: [ContactType.Patient]
  };

  return (
    <ContactPicker
      {...props}
      filter={filter}
      onResolveSuggestionItem={onResolveSuggestionItem}
    />
  );
};
