import { observer } from "mobx-react-lite";
import { FunctionComponent, useState } from "react";

import {
  ContextualMenuItemType,
  IconButton,
  IContextualMenuItem,
  IOverflowSetItemProps,
  ResizableOverflowBar,
  Stack,
  StackItem,
  TooltipHost,
  useResizeElementObserver,
  useTheme
} from "@bps/fluent-ui";
import { SubmitActionCode } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";
import { AutofillDto } from "@libs/gateways/document/DocumentGateway.dtos.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { LinkButton } from "@ui-components/navigation/LinkButton.tsx";

import { AutofillQuickAddEditDialog } from "./autofillQuickAddEdit/AutofillQuickAddEditDialog.tsx";

export interface ClinicalAutoFillToolBarProps {
  onClick: (shortcut: AutofillDto) => Promise<void>;
}
const OVERFLOW_KEY = "overflow";

export const ClinicalAutoFillToolBar: FunctionComponent<ClinicalAutoFillToolBarProps> =
  observer(({ onClick }) => {
    const [hideCreateAutofullDialog, sethideCreateAutofillDialog] =
      useState<boolean>(true);

    const { setElement, element } = useResizeElementObserver();

    const theme = useTheme();
    const { document } = useStores();

    const container = element?.querySelector("[class*=container]");

    const { activeAutofills, autofills } = document;
    const getText = (autofill: AutofillDto) => {
      return autofill.documentStatus === SubmitActionCode.PublishToEveryoneCode
        ? `${autofill.shortcut} - ${autofill.name} (Shared)`
        : `${autofill.shortcut} - ${autofill.name}`;
    };

    const clinicalAutoFillToolBar = () => {
      const inactiveAutofills: AutofillDto[] = autofills.filter(
        x => !activeAutofills.includes(x)
      );

      const stortedAutofills = Array.from(activeAutofills).sort((a, b) =>
        a.shortcut.localeCompare(b.shortcut)
      );

      const items: IOverflowSetItemProps[] = stortedAutofills.map(x => ({
        key: x.id,
        shortcut: x,
        name: x.name,
        onClick: async () => {
          await onClick(x);
        }
      }));

      const inactiveAutofillsMenuItems: IContextualMenuItem[] = [];

      if (container) {
        let accumulateWidth = 0;
        for (let i = 0; i < container.childElementCount; i++) {
          accumulateWidth += container.children[i].clientWidth;
          if (accumulateWidth > container.clientWidth) {
            inactiveAutofillsMenuItems.push(items[i]);
          }
        }
      }

      if (inactiveAutofills.length > 0) {
        inactiveAutofillsMenuItems.push({
          key: "section1",
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            title: "Inactive shortcuts (click to insert only)",
            topDivider: true,
            bottomDivider: true,
            items: inactiveAutofills.map(x => ({
              key: x.shortcut,
              text: getText(x),
              onClick: () => {
                onClick(x);
              }
            }))
          }
        });
      }

      const onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
        const shortcut: AutofillDto = item.shortcut;

        return (
          <TooltipHost content={shortcut?.name}>
            <LinkButton
              onClick={item.onClick}
              styles={{
                root: {
                  color: theme.palette.themePrimary
                }
              }}
            >
              {shortcut?.shortcut}
            </LinkButton>
          </TooltipHost>
        );
      };

      const onRenderOverflowButton = (
        overflowItems: IOverflowSetItemProps[] | undefined
      ): JSX.Element => {
        const overflowButtonMenuItems: IOverflowSetItemProps[] | undefined =
          overflowItems
            ?.filter(x => x.key !== OVERFLOW_KEY)
            .map(x => {
              return { ...x, text: getText(x?.shortcut) };
            });

        overflowButtonMenuItems?.push({
          key: "section1",
          itemType: ContextualMenuItemType.Section,
          sectionProps: {
            title: "Inactive shortcuts (click to insert only)",
            topDivider: true,
            bottomDivider: true,
            items: inactiveAutofills.map(x => ({
              key: x.shortcut,
              text: getText(x),
              onClick: () => {
                onClick(x);
              }
            }))
          }
        });

        return (
          <IconButton
            menuIconProps={{ iconName: "More" }}
            menuProps={{
              items: overflowButtonMenuItems ?? []
            }}
            styles={{ root: { alignItems: "center" } }}
          />
        );
      };

      return (
        <Stack
          verticalFill
          horizontal
          horizontalAlign="space-between"
          verticalAlign="center"
          styles={{
            root: {
              backgroundColor: theme.palette.neutralLighter
            }
          }}
        >
          <StackItem styles={{ root: { overflow: "hidden", flexGrow: 1 } }}>
            <div
              ref={r => {
                if (r && !element) {
                  setElement(r);
                }
              }}
            >
              <ResizableOverflowBar
                items={items}
                onRenderOverflow={onRenderOverflowButton}
                onRenderItem={onRenderItem}
                itemSize={0}
                itemsGap={0}
                styles={{
                  wrapper: {
                    height: "100%",
                    padding: 4
                  },
                  container: { height: "100%" }
                }}
              />
            </div>
          </StackItem>

          <StackItem styles={{ root: { display: "flex" } }}>
            {inactiveAutofillsMenuItems.length > 0 && (
              <IconButton
                menuIconProps={{ iconName: "More" }}
                menuProps={{
                  items: inactiveAutofillsMenuItems ?? []
                }}
                styles={{ root: { alignItems: "center" } }}
              />
            )}

            <IconButton
              styles={{
                root: {
                  width: 48,
                  borderLeft: `1px solid ${theme.palette.white}`
                }
              }}
              iconProps={{
                iconName: "BpAddUpdate"
              }}
              onClick={() => sethideCreateAutofillDialog(false)}
              title="Add / update autofill"
            />
          </StackItem>
          <AutofillQuickAddEditDialog
            isDialogHidden={hideCreateAutofullDialog}
            onDismiss={() => sethideCreateAutofillDialog(true)}
          />
        </Stack>
      );
    };
    return clinicalAutoFillToolBar();
  });
