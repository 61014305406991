import {
  FunctionComponent,
  HTMLAttributes,
  useEffect,
  useRef,
  useState
} from "react";

import {
  GenericSelect,
  GenericSelectProps,
  GenericSelectTypeSwitcher,
  Option,
  PersonaSize
} from "@bps/fluent-ui";
import { isDefined, unique } from "@bps/utils";
import {
  ContactResultItemDto,
  ContactStatus
} from "@libs/gateways/practice/PracticeGateway.dtos.ts";
import { ContactsFilter } from "@shared-types/practice/contacts-filter.interface.ts";
import { useStores } from "@stores/hooks/useStores.ts";
import { Contact } from "@stores/practice/models/Contact.ts";
import { Persona } from "@ui-components/persona/Persona.tsx";

interface InternalContactsSelectProps
  extends Pick<
      GenericSelectProps,
      | "showCountCoin"
      | "styles"
      | "label"
      | "required"
      | "calloutWidth"
      | "isAllSelected"
      | "onAllSelected"
      | "disabled"
      | "onFocus"
      | "onBlur"
      | "showAllSelected"
      | "hideSelectAllButton"
    >,
    Pick<HTMLAttributes<HTMLElement>, "id" | "datatype"> {
  contactFilter?: Omit<ContactsFilter, "search">;
  placeholder?: string;
  take?: number;
  includeInactive?: boolean;
  includeDeceased?: boolean;
  className?: string;
  resultFilterPredicate?: (contact: ContactResultItemDto) => boolean;
}

export type ContactsSelectProps =
  GenericSelectTypeSwitcher<InternalContactsSelectProps>;

export const ContactsSelect: FunctionComponent<ContactsSelectProps> = ({
  includeDeceased,
  includeInactive,
  contactFilter,
  take = 10,
  placeholder,
  resultFilterPredicate,
  ...rest
}) => {
  const { practice } = useStores();
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState<boolean>(false);
  const [contacts, setContacts] = useState<Contact[]>([]);

  const isMounted = useRef<boolean>(true);

  // get contacts if component has been unmount and mount again with some keys
  useEffect(() => {
    const ids = Array.isArray(rest.selectedKeys)
      ? rest.selectedKeys
      : [rest.selectedKeys];

    if (!!ids.length && contacts.length === 0) {
      Promise.all(
        ids.filter(isDefined).map(id => practice.getContact(id))
      ).then(result => {
        if (isMounted.current) setContacts(result);
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [practice]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const statuses: ContactStatus[] = [
    ContactStatus.Active,
    ContactStatus.Inactive,
    ContactStatus.Deceased
  ].filter(
    s =>
      !(!includeInactive && s === ContactStatus.Inactive) &&
      !(!includeDeceased && s === ContactStatus.Deceased)
  );

  const pickedOptions = contacts.filter(
    c => rest.selectedKeys && rest.selectedKeys.includes(c.id)
  );

  const hasSelected = (dto: ContactResultItemDto) => {
    return !!rest.selectedKeys && !rest.selectedKeys.includes(dto.id);
  };

  const onSearch = async (value: string | undefined) => {
    if (!value) {
      return clearNotPickedOptions();
    }
    // fetch contacts by arguments, set contacts list
    if (!loading) setLoading(true);
    try {
      const result = await practice.fetchContacts({
        filter: {
          take,
          statuses,
          ...contactFilter,
          search: value
        },
        // excluded previously selected contacts
        resultFilterPredicate: dto =>
          resultFilterPredicate
            ? resultFilterPredicate(dto) && hasSelected(dto)
            : true
      });
      // set values
      setContacts(unique([...pickedOptions, ...result.results]));
    } catch (e) {
      setError(e.message);
    } finally {
      setLoading(false);
    }
  };

  const clearNotPickedOptions = () => {
    // clear values if all unselected
    if (!rest.selectedKeys || !rest.selectedKeys.length) return setContacts([]);
    setContacts(pickedOptions);
  };

  const contactsDataOptions: Option[] = (contacts ?? []).map(data => ({
    key: data.id,
    text: data.reversedName ?? "",
    data
  }));

  return (
    <GenericSelect
      searchBoxProps={{
        onSearch
      }}
      placeholder={placeholder}
      options={contactsDataOptions}
      errorMessage={error}
      loading={loading}
      onListOpened={value => {
        if (!value) {
          setContacts(currentContacts =>
            currentContacts.filter(c => rest.selectedKeys?.includes(c.id))
          );
        }
      }}
      onRenderOption={(option: Option<Contact>) => {
        return (
          <Persona
            id={option.data!.id}
            size={PersonaSize.size24}
            text={option.text}
            imageInitials={option.data!.initials}
          />
        );
      }}
      {...rest}
    />
  );
};
