import { FontSizes, IDetailsRowStyles, ITheme } from "@bps/fluent-ui";

export const getDetailsListFieldRowStyles = (options: {
  theme: ITheme;
  disabled?: boolean;
}): Partial<IDetailsRowStyles> => ({
  root: {
    cursor: options.disabled ? "default" : "pointer",
    backgroundColor: options.disabled
      ? options.theme.semanticColors.disabledBackground
      : undefined,

    ".ms-DetailsRow-check": {
      height: "100%"
    },
    ".ms-Check": {
      position: "relative",
      height: 20,
      width: 20,
      i: {
        height: 20,
        width: 20,
        fontSize: FontSizes.size20,
        color: options.disabled
          ? options.theme.semanticColors.disabledText
          : options.theme.palette.neutralSecondary
      },
      "i:last-child": {
        display: "none"
      },
      "::after": {
        width: 10,
        height: 10,
        borderRadius: "50%",
        position: "absolute",
        left: 5,
        right: 0,
        transitionProperty: "border-width",
        transitionDuration: "200ms",
        transitionTimingFunction: " cubic-bezier(0.4, 0, 0.23, 1)",
        boxSizing: "border-box",
        borderWidth: " 5px",
        borderStyle: "solid",
        borderColor: "black",
        background: "black",
        top: 5
      },

      "& .is-checked": {
        "::before": {
          display: "none"
        },
        ":after": {
          content: "''",
          borderColor: options.disabled
            ? options.theme.semanticColors.disabledBorder
            : options.theme.palette.themePrimary,
          background: options.disabled
            ? options.theme.semanticColors.disabledBackground
            : options.theme.palette.themePrimary,
          top: 5
        }
      }
    },
    ":hover": {
      backgroundColor: options.disabled
        ? options.theme.semanticColors.disabledBackground
        : options.theme.palette.neutralLighter,

      ".ms-Check": {
        "::after": {
          content: "''",
          borderColor: "black",
          background: "black",
          top: 5
        }
      },
      ".ms-Check.is-checked": {
        "::after": {
          content: "''",
          borderColor: options.disabled
            ? options.theme.semanticColors.disabledBorder
            : options.theme.palette.themePrimary,
          background: options.disabled
            ? options.theme.semanticColors.disabledBackground
            : options.theme.palette.themePrimary,
          top: 5
        }
      }
    }
  }
});
