import { FunctionComponent } from "react";
import { Field, FieldRenderProps } from "react-final-form";

import {
  mergeFuncStyles,
  OptionsSelect,
  OptionsSelectProps,
  useTheme
} from "@bps/fluent-ui";

import { ExposedFieldProps } from "../FinalForm.tsx";
import { FormItemField, FormItemFieldProps } from "../FormItemField.tsx";
import { formatUndefined } from "../utils.ts";
import { getFieldBorderColors } from "./utils.ts";

type HtmlElementType = HTMLElement;

export type OptionsSelectFieldProps = Omit<
  OptionsSelectProps,
  "label" | "selectedKeys" | "onChangeSelectedKeys"
> & {
  label?: string | JSX.Element;
  horizontalLabel?: boolean;
  fieldItemStyles?: FormItemFieldProps["styles"];
} & ExposedFieldProps<OptionsSelectProps["selectedKeys"], HtmlElementType>;

const OptionsSelectFieldAdapter = (
  props: FieldRenderProps<OptionsSelectProps["selectedKeys"], HtmlElementType> &
    Omit<
      OptionsSelectProps,
      "label" | "selectedKeys" | "onChangeSelectedKeys"
    > & {
      label?: string;
      horizontalLabel?: boolean;
      fieldItemStyles?: FormItemFieldProps["styles"];
    }
) => {
  const theme = useTheme();
  const {
    input: { value, onChange, onBlur, onFocus, name },
    meta,
    styles,
    required,
    label,
    disabled,
    horizontalLabel,
    fieldItemStyles,
    ...optionsSelectProps
  } = props;

  return (
    <FormItemField
      name={name}
      required={required}
      label={label}
      horizontalLabel={horizontalLabel}
      styles={fieldItemStyles}
    >
      <OptionsSelect
        onBlur={onBlur}
        onFocus={onFocus}
        hideSelectAllButton={optionsSelectProps.options.length < 2}
        {...optionsSelectProps}
        selectedKeys={value as any}
        onChangeSelectedKeys={onChange}
        disabled={disabled}
        required={required}
        styles={mergeFuncStyles(getFieldBorderColors(meta, theme), styles)}
      />
    </FormItemField>
  );
};

export const OptionsSelectField: FunctionComponent<
  OptionsSelectFieldProps
> = props => (
  <Field
    {...props}
    format={formatUndefined(!!props.multiSelect)}
    component={OptionsSelectFieldAdapter}
  />
);
