import { FC } from "react";
import { To } from "react-router-dom";

import { MessageBar, MessageBarType, Stack, Text } from "@bps/fluent-ui";

import { Navigate } from "./navigation/Navigate.tsx";

type ErrorBlockProps = {
  errorText: string;
  linkText?: string;
  to?: To;
};

export const ErrorBlock: FC<ErrorBlockProps> = ({
  errorText,
  linkText,
  to
}) => {
  return (
    <MessageBar
      messageBarIconProps={{ iconName: "Blocked2" }}
      messageBarType={MessageBarType.error}
      styles={{
        root: { padding: 4 },
        innerText: {
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          span: {
            flexGrow: 1,
            display: "flex",
            justifyContent: "space-between"
          }
        }
      }}
    >
      <Stack
        styles={{ root: { width: "100%" } }}
        horizontal
        tokens={{ childrenGap: 16 }}
      >
        <Text>{errorText}</Text>
        {linkText && to && (
          <Stack.Item grow={0} shrink={0}>
            <Navigate to={to}>{linkText}</Navigate>
          </Stack.Item>
        )}
      </Stack>
    </MessageBar>
  );
};
