import { FunctionComponent, useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";

import { hasMore } from "@libs/api/utils/paging.utils.ts";
import {
  IMaybePromiseObservable,
  QueryResult
} from "@libs/utils/promise-observable/promise-observable.utils.ts";

export type ScrollListenerProps = {
  /**
   * How long to wait before triggering onScroll related events. Calls are delayed for performance.
   * Default is 200ms.
   */
  delay?: number;
  /**
   * This is called when the scrollable element has been scrolled to the end (after the configured delay)
   */
  onScrolledToBottom: () => void;
  /**
   * The search and next query results are used to trigger getting additional data until the scrollable area is full.
   */
  searchResults: IMaybePromiseObservable<QueryResult<any>>;
};

/**
 * ScrollListener exposes a callback that will be called when the associated
 * scrollable element is scrolled to the end.
 */
export const ScrollListener: FunctionComponent<ScrollListenerProps> = ({
  searchResults,
  onScrolledToBottom,
  delay
}) => {
  const { ref, inView } = useInView();
  const timeout = useRef(0);

  useEffect(() => {
    window.clearTimeout(timeout.current);
    if (inView) {
      timeout.current = window.setTimeout(() => {
        if (
          !searchResults.pending &&
          searchResults.value &&
          hasMore(searchResults.value)
        ) {
          onScrolledToBottom();
        }
      }, delay || 200);
    }
  }, [
    inView,
    delay,
    onScrolledToBottom,
    searchResults.pending,
    searchResults.value
  ]);

  // hide component during testing as window.IntersectionObserver doesn't exist
  if (!window.IntersectionObserver) {
    return null;
  }

  // -8 top margin is to try fix bug where listener doesn't trigger sometimes when user has scrolled to the bottom
  return <div ref={ref} style={{ marginTop: -8 }} />;
};
